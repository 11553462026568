import React, { useState, useEffect, useMemo } from "react";
import Sidebar from "../sidebar/Sidebar";
import axios from "axios";
import { TbFileExport } from "react-icons/tb";
import group10 from "../images/Group 10.png";
import group11 from "../images/Group 11.png";
import group12 from "../images/Group 12.png";
import { CSVLink } from "react-csv";
import SyncLoader from "react-spinners/SyncLoader";
import PopUP from "./../Popup/PopUP";
import API_URL from "../../configs/config";
function Customers() {
  //state
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [, setError] = useState(null);
  const [inputdata, setInputdata] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [ticketType, setTicketType] = useState("typeA");
  const [lodding, setLodding] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [capture, setCapture] = useState();
  const [payments, setPayments] = useState([]);

  // for download exel file
  const headers = [
    { label: "Customer name", key: "notes.name" },
    { label: "Phone number", key: "notes.phone" },
    { label: "Email", key: "notes.email" },
    { label: "Attended Date", key: "notes.date" },
    { label: "No. of tickets", key: "notes.confirm_no_of_ticket" },
    { label: "Bank", key: "bank" },
    { label: "Total amount", key: `amount` },
    { label: "Method", key: "method" },
    { label: "Status", key: "status" },
  ];

  //sidebar toggle
  const toggleClick = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleRowClick = (id) => {
    setExpandedRowId(expandedRowId === id ? null : id);
  };

  //for input change
  const handleChange = (e) => {
    setInputdata(e.target.value);
  };

  //fetch data
  useEffect(() => {
    const fetchPayments = async () => {
      try {
        setLodding(true);
        const response = await axios.get(API_URL+"payments");
        const fetchedPayments = response.data || [];

        const capturedPayments = fetchedPayments.filter(
          (payment) => payment.status === "captured"
        );
        const savedPayments =
          JSON.parse(localStorage.getItem("payments")) || [];
        const mergedPayments = fetchedPayments.map((payment) => {
          const savedPayment = savedPayments.find((p) => p.id === payment.id);
          return savedPayment
            ? { ...payment, statuus: savedPayment.statuus }
            : payment;
        });

        setPayments(mergedPayments);
        // setFilteredPayments(capturedPayments);
        setCapture(capturedPayments);
        localStorage.setItem("payments", JSON.stringify(mergedPayments));
        localStorage.setItem("payment", JSON.stringify(fetchedPayments));
      } catch (err) {
        setError(err.message);
      } finally {
        setLodding(false);
      }
    };

    fetchPayments();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    //filter data
    const fdata = payments.filter((payment) => {
      const { notes } = payment;
      return (
        payment.amount.toString().includes(inputdata) ||
        payment.status.toString().includes(inputdata) ||
        (notes.name && notes.name.toString().includes(inputdata)) ||
        (notes.email && notes.email.toString().includes(inputdata)) ||
        (notes.phone && notes.phone.toString().includes(inputdata)) ||
        (notes.conform_number_of_ticket &&
          notes.conform_number_of_ticket.toString().includes(inputdata)) ||
        (notes.date && notes.date.toString().includes(inputdata))
      );
    });
    setFilteredPayments(fdata);
  };

  const recordPerPage = 120;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = filteredPayments.length > 0 ? filteredPayments : payments;
  const npage = Math.ceil(payments.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((n) => n + 1);

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage < npage) setCurrentPage(currentPage + 1);
  };

const handleTicketTypeChange = (type) => {
    setTicketType(type);
  
    
    const filtered = payments.filter((payment) => {
      const totalQty =
        payment.amount / payment.notes.confirm_no_of_ticket / 100;
      const matchesTicketType =
        (type === "InRegular" && totalQty === 629) ||
        (type === "InRegular" && totalQty === 799) ||
        (type === "InVIP" && totalQty === 1349) ||
        (type === "CouplePasses" && totalQty === 1499) ||
        (type === "Group05" && totalQty === 3699) ||
        (type === "Group10" && totalQty === 6599) ||
        (type === "Group20" && totalQty === 11999);

      return matchesTicketType;
    });
    setFilteredPayments(filtered);
    setCurrentPage(1);
  };

  
  const handleRefresh = () => {
    setInputdata("");
    setFilteredPayments(payments);
    setFilteredPayments(capture);
    setCurrentPage(1);
  };

  //set totle income
  const totalRevenue = useMemo(() => {
    return payments
      .filter((payment) => payment.status !== "failed")
      .reduce((total, payment) => {
        const tickets = Number(Math.floor(payment.amount / 100)) || 0;
        return total + tickets;
      }, 0);
  }, [payments]);

  const totalTickets = useMemo(() => {
    return payments
      .filter((payment) => payment.status !== "failed")
      .reduce((total, payment) => {
        const tickets = Number(payment.notes.confirm_no_of_ticket) || 0;
        return total + tickets;
      }, 0);
  }, [payments]);

  const totleCustomers = useMemo(() => {
    return payments.filter((payment) => payment.status !== "failed")
      .length;
  }, [payments]);

  const totlecustomer = payments.length;

  const customerPercentage = useMemo(() => {
    return totlecustomer > 0 ? (totleCustomers / totlecustomer) * 100 : 0;
  }, [totleCustomers, totlecustomer]);

  const handleTicketStatusClick = (payment) => {
    setSelectedPayment(payment);
  };

  const handleSaveStatus = async (newStatus) => {
    if (!selectedPayment) return;

    const updatedPayments = payments.map((payment) =>
      payment.id === selectedPayment.id
        ? { ...payment, ticket_States: newStatus }
        : payment
    );

    setPayments(updatedPayments);
    setFilteredPayments(updatedPayments);

    try {
      await axios.post(API_URL+"payments/status", {
        id: selectedPayment.id,
        newStatus: newStatus,
      });
      localStorage.setItem("payments", JSON.stringify(updatedPayments));
    } catch (error) {
      console.error("Error updating ticket status:", error);
    }

    setSelectedPayment(null);
  };

  //group filter
  const handleGroupTicketTypeChange = (type) => {
    setTicketType(type);
    const filtered = payments.filter((payment) => {
      const totalQty = payment.amount / 100;
      const matchesTicketType =
        (type === "Group05" && totalQty === 3699) ||
        (type === "Group10" && totalQty === 6599) ||
        (type === "Group20" && totalQty === 11999);
      return matchesTicketType;
    });
    setFilteredPayments(filtered);
    setCurrentPage(1);
  };

  return (
    <>
      {/* header section-1 */}
      <div className="font-[Poppins] flex ">
        {sidebarToggle ? <div className="w-[320px] nirav"></div> : null}
        <Sidebar toggleClick={toggleClick} sidebarToggle={sidebarToggle} />
        <div className="w-[100%] ">
          <div className=" 2xl:w-[90%] w-[95%] m-auto 2xl:mt-16 mt-10  xl:p-2 lg:p-10  p-2">
            <div className="sm:flex sm:justify-between sm:items-center">
              <h1 className="font-medium 2xl:text-2xl text-xl">Hello SMJ 👋🏼</h1>
            </div>

            {/* section-2 */}
            <div className="border 2xl:mt-10 mt-7 2xl:rounded-[30px] rounded-[20px] 2xl:px-14 px-7 2xl:py-8 py-5  bg-white shadow-sm">
              <div className="grid md:grid-cols-3">
                <div className="flex gap-5 mx-2 md:items-center max-md:my-5">
                  <img
                    className="max-2xl:w-[60px] max-2xl:h-[60px]"
                    src={group10}
                    alt="double human"
                  />
                  <div>
                    <h5 className="text-sm font-normal text-gray-500">
                      Total Customers
                    </h5>
                    <h1 className="font-semibold 2xl:text-3xl text-2xl my-1">
                      {totleCustomers}
                    </h1>
                    <span className="text-xs font-medium">
                      <i className="fa-solid fa-arrow-up text-green-400"></i>
                      <span className="text-green-400 mx-2">
                        {customerPercentage.toFixed()}%
                      </span>
                      This Week
                    </span>
                  </div>
                </div>
                <div className="flex gap-5 md:justify-center mx-2 md:border-l-2 md:border-r-2 items-center max-md:my-5">
                  <img
                    className="max-2xl:w-[60px] max-2xl:h-[60px]"
                    src={group12}
                    alt="computer"
                  />
                  <div>
                    <h5 className="text-sm font-normal text-gray-500">
                      No of ticket sold
                    </h5>
                    <h1 className="font-semibold 2xl:text-3xl text-2xl my-1">
                      {totalTickets}
                    </h1>
                  </div>
                </div>
                <div className="flex gap-5 items-center mx-2 md:justify-end max-md:my-5">
                  <img
                    className="max-2xl:w-[60px] max-2xl:h-[60px]"
                    src={group11}
                    alt="sigle human"
                  />
                  <div>
                    <h5 className="text-sm font-normal text-gray-500">
                      Total Revenue
                    </h5>
                    <h1 className="font-semibold 2xl:text-3xl text-2xl my-1">
                      {totalRevenue}
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            {/* section-3 */}
            <div className="border 2xl:mt-10 mt-7 2xl:rounded-[30px] rounded-[20px] px-10 py-8  bg-white shadow-sm">
              <div className="mx-2 my-2 flex flex-wrap justify-between items-center">
                <div className="mb-3">
                  <h1 className="font-semibold 2xl:text-2xl text-xl">
                    All Customers
                  </h1>
                </div>
                {/* filter table data form */}
                <div className="flex items-center flex-wrap gap-1">
                  <form
                    className="filterinput"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      onChange={handleChange}
                      type="text"
                      name="inputdata"
                      value={inputdata}
                      className="bg-[#F9FBFF]  sm:px-5 max-sm:w-[68%] pl-3 2xl:py-2 py-[0.40rem] border border-gray-300  outline-none focus:ring-2 focus:ring-[#069ea1] rounded-lg text-xs max-md:my-1"
                      placeholder="Search"
                    />
                    <button
                      type="submit"
                      className="mr-1 ml-3 cursor-pointer bg-[#069ea1] hover:bg-teal-500 hover:shadow-md button text-white py-[0.4rem] 2xl:text-sm text-xs rounded-lg 2xl:px-3 px-2"
                    >
                      Search
                    </button>
                  </form>
                  {/* filter table data by ticketname */}
                  <div className="flex justify-between items-center flex-wrap">
                    <div className="text-xs bg-[#F9FBFF] px-3 py-[0.40rem] border border-gray-300  rounded-lg max-md:my-1 mr-1 font-semibold">
                      <span className="2xl:text-sm text-xs">Ticket name: </span>
                      <select
                        value={ticketType}
                        onChange={(e) => handleTicketTypeChange(e.target.value)}
                        className="px-1 rounded-sm font-medium cursor-pointer"
                      >
                        <option value="" className="bg-[#F9FBFF] font-medium">
                          --Ticket-type--
                        </option>
                        <option
                          value="InRegular"
                          className="bg-[#F9FBFF] font-medium"
                        >
                          In Regular
                        </option>
                        <option
                          value="InVIP"
                          className="bg-[#F9FBFF] font-medium"
                        >
                          In VIP
                        </option>
                        <option
                          value="CouplePasses"
                          className="bg-[#F9FBFF] font-medium"
                        >
                          Couple Passes
                        </option>
                      </select>
                    </div>
                    <div className="text-xs bg-[#F9FBFF] px-3 py-[0.40rem] border border-gray-300  rounded-lg max-md:my-1 mr-1 font-semibold">
                      <span className="2xl:text-sm text-xs">
                        Group Filter:{" "}
                      </span>
                      <select
                        value={ticketType}
                        onChange={(e) =>
                          handleGroupTicketTypeChange(e.target.value)
                        }
                        className="px-1 rounded-sm font-medium cursor-pointer"
                      >
                        <option value="" className="bg-[#F9FBFF] font-medium">
                          --Ticket-type--
                        </option>
                        <option
                          value="Group05"
                          className="bg-[#F9FBFF] font-medium"
                        >
                          Group 05
                        </option>
                        <option
                          value="Group10"
                          className="bg-[#F9FBFF] font-medium"
                        >
                          Group 10
                        </option>
                        <option
                          value="Group20"
                          className="bg-[#F9FBFF] font-medium"
                        >
                          Group 20
                        </option>
                      </select>
                    </div>

                    {/* refresh button */}
                    <CSVLink
                      data={payments}
                      headers={headers}
                      filename="Norta-Dashboard_data.csv"
                    >
                      <button className="bg-[#069ea1] hover:bg-teal-500 hover:shadow-md button 2xl:px-3 px-2 py-[0.38rem] text-white 2xl:text-sm text-xs rounded-lg flex justify-center items-center gap-1">
                        Export <TbFileExport />
                      </button>
                    </CSVLink>

                    <i
                      onClick={handleRefresh}
                      className="fa-solid fa-arrows-rotate ml-2 cursor-pointer border border-gray-300 px-2 2xl:py-[0.45rem] py-[0.40rem] rounded-md bg-[#F9FBFF]"
                    ></i>
                  </div>
                </div>
              </div>

              {/* section-4 table */}
              <div className="mt-6 relative overflow-x-auto  overflow-y-hidden">
                <table className="w-full border-collapse border-spacing-0">
                  <thead>
                    <tr className="text-[#b5b7c0] m-12 font-medium">
                      <th>Sr no</th>
                      <th>Customer name</th>
                      <th>Phone number</th>
                      <th>Atanded Date</th>
                      <th>No. of tickets</th>
                      <th>Ticket price</th>
                      <th>Total amount</th>
                      <th>Ticket name</th>
                      <th>Payment status</th>
                      <th>Ticket status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lodding ? (
                      <tr>
                        <td colSpan="11" className="text-center">
                          <div className="flex justify-center items-center w-full h-full">
                            <SyncLoader
                              margin={1}
                              speedMultiplier={1}
                              color={"#069ea1"}
                              loading={lodding}
                              size={10}
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      records.map((tdata, index) => {
                        const qut = tdata.amount;
                        const confirmNoOfTicket =
                          tdata.notes.confirm_no_of_ticket || 1;
                        const totalQut = qut / confirmNoOfTicket;
                        const ticketName = () => {
                          const value = totalQut / 100;
                          if (value === 629) {
                            return <p>In Regular</p>;
                          } else if (value === 799) {
                            return <p>In Regular</p>;
                          } else if (value === 1349) {
                            return <p>In VIP</p>;
                          } else if (value === 1499) {
                            return <p>Couple Passes</p>;
                          } else if (value === 3699) {
                            return <p>Group 05</p>;
                          } else if (value === 6599) {
                            return <p>Group 10</p>;
                          } else if (value === 11999) {
                            return <p>Group 20</p>;
                          } else if (value === 749.5) {
                            return <p>Couple Passes*</p>;
                          } else {
                            return <p>None</p>;
                          }
                        };
                        const serialNumber = firstIndex + index + 1;

                        return (
                          <React.Fragment key={tdata.id}>
                            <tr
                              className="h-10 text-sm m-12 cursor-pointer font-medium"
                              onClick={() => handleRowClick(tdata.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <td>{serialNumber}</td>
                              <td>{tdata.notes.name}</td>
                              <td>{tdata.notes.phone}</td>
                              <td>{tdata.notes.date}</td>
                              <td>{tdata.notes.confirm_no_of_ticket}</td>
                              <td>₹ {(totalQut / 100).toFixed(2)}</td>
                              <td>₹ {(tdata.amount / 100).toFixed(2)}</td>
                              <td>
                                <button>{ticketName()}</button>
                              </td>
                              <td className="py-4 px-6">
                                <span
                                  className={`px-2 py-1 rounded-full ${tdata.status === "captured"
                                      ? "bg-green-200 text-green-800"
                                      : "bg-red-200 text-red-800"
                                    }`}
                                >
                                  {tdata.status}
                                </span>
                              </td>
                              <td>
                                <button
                                  onClick={() => handleTicketStatusClick(tdata)}
                                  className="border px-2 py-1 rounded-md bg-[#069ea1] text-white"
                                >
                                  {tdata.ticket_States}
                                </button>
                              </td>
                            </tr>

                            {expandedRowId === tdata.id && (
                              <tr>
                                <td colSpan="12">
                                  <div className="border m-1 border-gray-300 rounded-lg">
                                    <div className="flex p-5 justify-between ">
                                      <div>
                                        <h2 className="text-start text-sm mb-1 text-[#B5B7C0]">
                                          Email Id:{" "}
                                          <span className="text-black text-xs">
                                            {tdata.notes.email}
                                          </span>{" "}
                                        </h2>
                                        <h2 className="text-start text-sm mb-1 text-[#B5B7C0]">
                                          Order Id:{" "}
                                          <span className="text-black text-xs">
                                            {tdata.order_id}
                                          </span>{" "}
                                        </h2>

                                        <h2 className="text-start text-sm mb-1 text-[#B5B7C0]">
                                          Method:{" "}
                                          <span className="text-black text-xs">
                                            {tdata.method}
                                          </span>{" "}
                                        </h2>
                                      </div>
                                      <div>
                                        <div className="flex gap-5 items-center">
                                          <h2 className="text-[#B5B7C0]">
                                            OTP for ticket collection
                                          </h2>
                                          <h2 className="text-[#00A198]">
                                            Resend OTP to client
                                          </h2>
                                        </div>
                                        <form className="flex gap-2 mt-2">
                                          <input
                                            type="text"
                                            className="border rounded-md py-1 px-5"
                                          />
                                          <button className="px-5 rounded-md py-1 bg-[#ea1d8467]">
                                            Submit
                                          </button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>

              {selectedPayment && (
                <PopUP
                  currentStatus={selectedPayment.status}
                  onClose={() => setSelectedPayment(null)}
                  onSave={handleSaveStatus}
                />
              )}
              {/* pagination */}
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className={`px-4 py-2 bg-teal-600 hover:bg-teal-500 text-white rounded-lg mr-2 transition duration-200 ease-in-out ${currentPage === 1
                      ? "opacity-50 cursor-not-allowed"
                      : "cursor-pointer"
                    }`}
                  aria-label="Previous Page"
                >
                  Prev
                </button>

                <div className="flex items-center space-x-2">
                  {numbers.slice(0, 3).map((number) => (
                    <button
                      key={number}
                      onClick={() => setCurrentPage(number)}
                      className={`px-4 py-2 rounded-lg transition duration-200 ease-in-out ${currentPage === number
                          ? "bg-teal-600 text-white"
                          : "bg-white text-teal-600 border border-teal-600 hover:bg-teal-100"
                        }`}
                      aria-current={currentPage === number ? "page" : undefined}
                    >
                      {number}
                    </button>
                  ))}

                  {currentPage < npage && (
                    <button
                      onClick={() => setCurrentPage(npage)}
                      className="px-4 py-2 rounded-lg transition duration-200 ease-in-out bg-white text-teal-600 border border-teal-600 hover:bg-teal-100"
                      aria-label={`Go to page ${npage}`}
                    >
                      &raquo; {/* Right arrow for "Next" */}
                    </button>
                  )}
                </div>

                <button
                  onClick={nextPage}
                  disabled={currentPage === npage}
                  className={`px-4 py-2 bg-teal-600 hover:bg-teal-500 text-white rounded-lg ml-2 transition duration-200 ease-in-out ${currentPage === npage
                      ? "opacity-50 cursor-not-allowed"
                      : "cursor-pointer"
                    }`}
                  aria-label="Next Page"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customers;

